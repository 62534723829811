<template>
  <span>
    <!-- queued statuses -->
    <span v-if="status === 'deployment_queued'" class="tag is-info">Queued for deployment</span>
    <span v-else-if="status === 'deactivation_queued'" class="tag is-info">Queued for deactivation</span>

    <!-- deploying/deactivating statuses -->
    <span v-else-if="status === 'deploying'" class="tag is-info">Deploying</span>
    <span v-else-if="status === 'deactivating'" class="tag is-info">Deactivating</span>

    <!-- final success statuses -->
    <span v-else-if="status === 'active'" class="tag is-success">Active</span>
    <span v-else-if="status === 'deactivated'" class="tag is-light">Deactivated</span>

    <!-- final failure statuses (each is appended with a button for retrial) -->
    <div v-else-if="['deployment_queueing_failed', 'deployment_failed', 'deactivation_queueing_failed', 'deactivation_failed'].indexOf(status) >= 0" class="tags has-addons">
      <span v-if="status === 'deployment_queueing_failed'" class="tag is-danger">Deployment queueing failed</span>
      <span v-else-if="status === 'deployment_failed'" class="tag is-danger">Deployment failed</span>
      <span v-else-if="status === 'deactivation_queueing_failed'" class="tag is-danger">deactivation queueing failed</span>
      <span v-else-if="status === 'deactivation_failed'" class="tag is-danger">Deactivation failed</span>
      <a
        class="tag is-light"
        href="#"
        @click.prevent="$emit('update_status', ['deployment_queueing_failed', 'deployment_failed'].indexOf(status) >= 0?'active':'deactivated')"
      >
        <span
          class="icon is-small"
        >
          <font-awesome-icon :icon="['fas', 'sync']" />
        </span>
      </a>
    </div>

    <!-- fallback status (just shows whatever is in the status prop) -->
    <span v-else class="tag is-light">{{status}}</span>
  </span>
</template>

<script>
export default {
  name: 'monitor-status-label',
  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>

import PozetronWebSharedLayout from './components/layout/pozetron-web-shared-layout.vue'

import config from './../app.config.json'

import MonitorsListScreen from './components/screens/MonitorsListScreen.vue'
import MonitorsSingleScreen from './components/screens/MonitorsSingleScreen.vue'

import MonitorsFormScreen from './components/screens/MonitorsFormScreen.vue'
import EventFormScreen from './components/screens/EventFormScreen.vue'

const routes = [
  {
    path: '/',
    component: PozetronWebSharedLayout,
    props: {
      devices_app_base_url: config.DEVICES_APP_BASE_URL,
      actions_app_base_url: '',
      dashboards_base_url: config.DASHBOARDS_BASE_URL,
      shop_base_url: config.SHOP_BASE_URL,
      tutorials_base_url: config.TUTORIALS_BASE_URL,
      billing_app_base_url: config.BILLING_APP_BASE_URL
    },
    children: [
      {
        path: '',
        component: MonitorsListScreen, name: 'monitors_list'
      },
      { path: '/monitors/:id_hash', component: MonitorsSingleScreen, name: 'monitors_single', props: true },
      {
        path: '/new',
        component: MonitorsFormScreen,
        name: 'new_action'
      },
      {
        path: '/events/new',
        component: EventFormScreen,
        name: 'new_event'
      }
    ]
  },
]

export default routes

<template>
  <div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              :value="name"
              @input="validation.name.$touch(); $emit('update:value', {'name': $event.target.value})"
              :class="['input', {'is-danger': validation.name.$error}]"
              type="text"
              placeholder="Choose a name"
            >
            <div v-if="validation.name.$error">
              <p class="help is-danger" v-if="validation.name.required.$invalid">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Devices</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <devices-field
              placeholder="Select devices"
              :multiple="true"
              :show_all_option="true"
              :modelValue="devices"
              @update:modelValue="validation.devices.$touch(); $emit('update:value', {'devices': $event})"
            />
            <div v-if="validation.devices.$error">
              <p class="help is-danger" v-if="validation.devices.required.$invalid">Field is required</p>
            </div>
          </div>

          <div class="panel" :style="{'margin-top': '1rem'}">
            <label class="panel-block">
              <input
                type="radio"
                value="normal"
                :checked="event_type === 'normal'"
                @change="validation.event_type.$touch(); $emit('update:value', {'event_type': $event.target.value})"
              />
              <span :style="{'margin-right': '.5rem'}">Normal events only</span>
              <span class="has-text-grey is-size-7">Actions will only be triggered on events that originate from specified devices directly.</span>
            </label>
            <label class="panel-block">
              <input
                type="radio"
                value="synthetic"
                :checked="event_type === 'synthetic'"
                @change="validation.event_type.$touch(); $emit('update:value', {'event_type': $event.target.value})"
              />
              <span :style="{'margin-right': '.5rem'}">Synthetic events only</span>
              <span class="has-text-grey is-size-7">Actions will only be triggered on events published by other actions associated with the specified devices.</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <h3 class="title is-4">
      <span>Meta filters</span> <toggle-button
        color="#fc6e51"
        :modelValue="filter_meta._active"
        @update:modelValue="validation.filter_meta._active.$touch(); $emit('update:value', {'filter_meta': {...filter_meta, '_active': $event}})"
      />
    </h3>
    <p class="subtitle is-6 has-text-grey">Filters to be applied on <strong>event meta-data</strong>, such as publish time.</p>

    <bulma-panel-collapsible
      v-if="filter_meta._active"
      :shadowless="true"
      :sections="[
        {key: 'standard', label: 'Standard'},
        {key: 'advanced', label: 'Advanced'}
      ]"
      :active_section="active_filter_meta_mode"
      @update:modelValue="handleFilterMetaModeChange($event)"
    >

      <template
        v-if="active_filter_meta_mode === 'standard'"
        v-slot:standard
      >
        <rule
          :showRemoveButton="false"
          :showDragHandle="false"
          :meta="true"
          :value="filter_meta.standard.rule"
          @update:value="validation.filter_meta.standard.rule.$touch(); $emit('update:value', {'filter_meta': {...filter_meta, 'standard': {...filter_meta.standard, 'rule': $event}}})"
        />
      </template>

      <template
        v-if="active_filter_meta_mode === 'advanced'"
        v-slot:advanced
      >
        <rule
          v-if="filter_meta.advanced.rule !== null"
          :showDragHandle="false"
          :meta="true"
          :value="filter_meta.advanced.rule"
          @update:value="validation.filter_meta.advanced.rule.$touch(); $emit('update:value', {'filter_meta': {...filter_meta, 'advanced': {...filter_meta.advanced, 'rule': $event}}})"
          @remove="validation.filter_meta.advanced.rule.$touch(); $emit('update:value', {'filter_meta': {...filter_meta, 'advanced': {...filter_meta.advanced, 'rule': null}}})"
        />

        <rule-add-meta v-else
          @update:value="validation.filter_meta.advanced.rule.$touch(); $emit('update:value', {'filter_meta': {...filter_meta, 'advanced': {...filter_meta.advanced, 'rule': $event}}})"
        />
      </template>

    </bulma-panel-collapsible>

    <hr />

    <h3 class="title is-4">Data filters</h3>
    <p class="subtitle is-6 has-text-grey">Filters to be applied on <strong>event data</strong>.</p>

    <bulma-panel-collapsible
      :shadowless="true"
      :sections="[
        {key: 'basic', label: 'Basic'},
        {key: 'standard', label: 'Standard'},
        {key: 'advanced', label: 'Advanced'}
      ]"
      :active_section="active_filter_mode"
      @update:modelValue="handleFilterModeChange($event)"
    >

      <template
        v-if="active_filter_mode === 'basic'"
        v-slot:basic
      >
        <div class="field has-addons">
          <div class="control">
            <a class="button is-static">
              <span>On <strong>button</strong></span>
            </a>
          </div>
          <div class="control">
            <div class="select">
              <select
                :value="filter.basic.button"
                @update:value="validation.filter.basic.button.$touch(); $emit('update:value', {'filter': {...filter, 'basic': {...filter.basic, 'button': $event.target.value}}})"
              >
                <option value="pressed">Pressed</option>
              </select>
            </div>
          </div>
        </div>
      </template>

      <template
        v-if="active_filter_mode === 'standard'"
        v-slot:standard
      >
      <rule
        :showRemoveButton="false"
        :showDragHandle="false"
        :value="filter.standard.rule"
        @update:value="validation.filter.standard.rule.$touch(); $emit('update:value', {'filter': {...filter, 'standard': {...filter.standard, 'rule': $event}}})"
      />
      </template>

      <template
        v-if="active_filter_mode === 'advanced'"
        v-slot:advanced
      >
        <rule
          v-if="filter.advanced.rule !== null"
          :showDragHandle="false"
          :value="filter.advanced.rule"
          @update:value="validation.filter.advanced.rule.$touch(); $emit('update:value', {'filter': {...filter, 'advanced': {...filter.advanced, 'rule': $event}}})"
          @remove="validation.filter.advanced.rule.$touch(); $emit('update:value', {'filter': {...filter, 'advanced': {...filter.advanced, 'rule': null}}})"
        />

        <rule-add v-else
          @update:value="validation.filter.advanced.rule.$touch(); $emit('update:value', {'filter': {...filter, 'advanced': {...filter.advanced, 'rule': $event}}})"
        />
      </template>

    </bulma-panel-collapsible>

  </div>
</template>

<script>
import DevicesField from './../../../common/DevicesField.vue'
import BulmaPanelCollapsible from './../../../common/BulmaPanelCollapsible.vue'
import RuleAdd from './../rules/RuleAdd.vue'
import RuleAddMeta from './../rules/RuleAddMeta.vue'
import Rule from './../rules/Rule.vue'

export default {
  name: 'step-0',
  components: {
    'bulma-panel-collapsible': BulmaPanelCollapsible,
    'devices-field': DevicesField,
    'rule-add': RuleAdd,
    'rule-add-meta': RuleAddMeta,
    'rule': Rule
  },
  props: {
    name: {
      type: String,
      required: true
    },
    devices: {
      type: Array,
      required: true
    },
    event_type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    },
    filter_meta: {
      type: Object,
      required: true
    },
    validation: {
      type: Object,
      required: true
    },
    handleFilterModeChange: {
      type: Function,
      required: true
    },
    handleFilterMetaModeChange: {
      type: Function,
      required: true
    }
  },
  emits: ['update:value'],
  computed: {
    active_filter_mode: function () {
      return Object.keys(this.filter).filter(key => this.filter[key]._active)[0]
    },
    active_filter_meta_mode: function () {
      return Object.keys(this.filter_meta).filter(key => this.filter_meta[key]._active)[0]
    }
  }
}
</script>

<template>
  <div>
    Single monitor screen
  </div>
</template>

<script>
export default {
  name: 'monitors-single-screen',
}
</script>

<template>
  <div class="level">
    <!-- Left side -->
    <div class="level-left">
      <div v-if="label" class="level-item">
        {{label}}
      </div>

      <div v-if="!hideKeyControls" class="level-item">
        <div class="field has-addons">
          <div class="control">
            <div class="button is-static toggle-wrapper">
              <toggle-button
                color="#fc6e51"
                :data-tooltip="value.per_key?'The \'per key\' filter is enabled for this rule':'The \'per key\' filter is disabled for this rule'"
                :modelValue="value.per_key"
                @update:modelValue="stateValue = {...stateValue, 'per_key': $event}"
              />
            </div>
          </div>
          <div
            :class="['control', {'has-tooltip-danger': v$.value.key.$error}]"
            :data-tooltip="
              v$.value.key.$error
                ?(v$.value.key.requiredIf.$invalid?'Field is required':null)
                :null
            "
          >
            <input
              :class="['input', {'is-danger': v$.value.key.$error}]"
              type="text"
              placeholder="Key"
              :disabled="!value.per_key"
              :value="value.per_key?value.key:'[any key]'"
              @input="stateValue = {...stateValue, 'key': $event.target.value}"
            >
          </div>
        </div>
      </div>
      <div class="level-item">
        day of week
      </div>
      <div class="level-item">
        is
      </div>
      <div
        :class="['level-item', {'has-tooltip-danger': v$.value.value.$error}]"
        :data-tooltip="
          v$.value.value.$error
            ?(v$.value.value.required.$invalid?'Field is required':null)
            :null
        "
      >
        <div class="buttons has-addons">
          <button
            v-for="day_of_week in ISO_DAYS_OF_WEEK"
            :key="day_of_week.id"
            :class="['button', value.value.indexOf(day_of_week.id) >= 0 ? 'is-pozetron-primary' : 'has-text-grey-light', {'is-danger is-light is-outlined': v$.value.value.$error}]"
            @click.prevent="stateValue = {...stateValue, 'value': value.value.indexOf(day_of_week.id) >= 0?value.value.filter(d => d !== day_of_week.id):[...value.value, day_of_week.id]}"
          >{{day_of_week.label}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'


const ISO_DAYS_OF_WEEK = [
  {id: 1, label: 'Mon'},
  {id: 2, label: 'Tue'},
  {id: 3, label: 'Wed'},
  {id: 4, label: 'Thu'},
  {id: 5, label: 'Fri'},
  {id: 6, label: 'Sat'},
  {id: 7, label: 'Sun'}
]


export default {
  name: 'rule-selector-day-of-week',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    hideKeyControls: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['update:value'],
  data() {
    return {
      stateValue: Object.assign({}, this.value),
    }
  },
  computed: {
    ISO_DAYS_OF_WEEK: function () {
      return ISO_DAYS_OF_WEEK
    }
  },
  watch: {
    v$: {
      handler: async function (currentv$) {
        await this.$nextTick()
        if (this.stateValue.$invalid_inner !== currentv$.value.$invalid) {
          this.stateValue = {...this.stateValue, '$invalid_inner': currentv$.value.$invalid}
        }
      },
      deep: true
    },
    value: {
      handler: function (newValue, oldValue) {
        if (!_.isEqual(oldValue, newValue)) {
          this.stateValue = Object.assign({}, newValue)
        }
      },
      deep: true
    },
    stateValue: {
      handler: async function (newStateValue, oldStateValue) {
        if (!_.isEqual(oldStateValue, newStateValue)) {
          // emit new value for parent component
          this.$emit('update:value', Object.assign({}, newStateValue))
          // touch rule validation (this fires the v$ watcher, which will attempt an update to the special value.$invalid_inner attr if needed)
          await this.$nextTick(); // wait for v$ to be populated
          this.v$.value.$touch();
        }
      },
      deep: true,
      immediate: true // run handler asap (else, stateValue's initialization from the 'value' prop won't be taken into account)
    }
  },
  validations: {
    value: {
      type: {
        mustBe: (value) => value === 'selector-day-of-week'
      },

      per_key: {
        required,
        mustBeBoolean: (value) => typeof value === 'boolean'
      },
      key: {
        requiredIf: requiredIf(function () {
          return this.value.per_key
        })
      },

      // NOTE: There is no 'comparator' field for day-of-week rules. Event values will match if they are in the specified values array.

      value: {
        required,
        // TODO: $each is no longer supported in latest vuelidate
        // needs fix
        // See https://vuelidate-next.netlify.app/migration_guide.html#removal-of-each-param
        $each: {
          mustBeDayOfWeek: (value) => ISO_DAYS_OF_WEEK.map(d => d.id).indexOf(value) >=0
        }
      }
    }
  }
}
</script>

<style scoped>
.button.is-static.toggle-wrapper {
  pointer-events: initial;
  cursor: initial;
}
</style>

import _ from 'lodash'


import {
  MONITOR_FORM_INITIAL_VALUE_FACTORY
} from './../constants'


/**
 * Recursively attempts removal of properties with key satisfying key_criteria
 * @param {Object} obj
 * @param {function (string):boolean} key_criteria
 *
 * @returns {Object}
*/
export function _deep_clean_object_properties(obj, key_criteria) {
  if (Array.isArray(obj)) {
    return obj.map(element => _deep_clean_object_properties(element, key_criteria))
  } else if (_.isObject(obj)) {
    let clean = {}
    Object.keys(obj).forEach(key => {
      if (key_criteria(key)) {
        clean[key] = _deep_clean_object_properties(obj[key], key_criteria)
      }
    })
    return clean
  }

  return obj
}


/**
 * Transforms form data into an API-ready format
*/
export const get_api_ready_form_data = (form_data) => {
  let result = {}
  const active_filter = Object.keys(form_data.filter).filter(key => form_data.filter[key]._active)[0]
  const active_filter_meta = Object.keys(form_data.filter_meta).filter(key => form_data.filter_meta[key]._active)[0]

  Object.keys(form_data).forEach(key => {
    switch (key) {

      case 'filter':
        result['filter'] = form_data.filter[active_filter]
        result['filter']['type'] = active_filter
        break;

      case 'filter_meta':
        if (form_data.filter_meta._active) {
          result['filter_meta'] = form_data.filter_meta[active_filter_meta]
          result['filter_meta']['type'] = active_filter_meta
        } else {
          result['filter_meta'] = null
        }
        break;

      case 'actions':
        Object.keys(form_data['actions']).forEach(action_key => {
          result[action_key] = form_data['actions'][action_key]._active
            ?form_data['actions'][action_key]
            :null
        })
        break;

      default:
        result[key] = form_data[key]
        break;

    }
  })

  return _deep_clean_object_properties(result, (key) => !key.startsWith('_') && !key.startsWith('$') && key !== 'id')
}


/**
 * Transforms api data into a form-ready format
*/
export const get_form_ready_data = (api_data) => {
  let result = MONITOR_FORM_INITIAL_VALUE_FACTORY()

  result.id = api_data.id
  result.name = api_data.name
  result.devices = api_data.devices
  result.event_type = api_data.event_type

  result.filter[api_data.filter.type] = api_data.filter
  result.filter[api_data.filter.type]._active = true

  if (api_data.filter_meta !== null) {
    result.filter_meta._active = true
    result.filter_meta[api_data.filter_meta.type] = api_data.filter_meta
    result.filter_meta[api_data.filter_meta.type]._active = true
  }

  Object.keys(api_data).filter(key => key.startsWith('action_')).forEach(action_key => {
    if (api_data[action_key]){
      result.actions[action_key] = api_data[action_key]
      result.actions[action_key]._active = true
    }
  })

  return _deep_clean_object_properties(result, (key) => ['created_at', 'updated_at'].indexOf(key) < 0)
}

<template>
  <div class="action">
    <div class="action-header field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Email</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                Sends an email message to an address of your choosing.
              </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
              <div class="level-item">
                <toggle-button
                  color="#fc6e51"
                  :modelValue="value._active"
                  @update:modelValue="$emit('update:value', {...value, '_active': $event})"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="value._active" class="action-fields">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Email address</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                :class="['input', {'is-danger': validation.email_address.$error}]"
                type="text"
                :value="value.email_address"
                @input="validation.email_address.$touch(); $emit('update:value', {...value, 'email_address': $event.target.value})"
              >
              <div v-if="validation.email_address.$error">
                <p class="help is-danger" v-if="validation.email_address.requiredIf.$invalid">Field is required</p>
                <p class="help is-danger" v-if="validation.email_address.email.$invalid">Field must be a valid email address</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Message</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <textarea
                class="textarea"
                :modelValue="value.message"
                @update:modelValue="$emit('update:value', {...value, 'message': $event.target.value})"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'action-email',
  props: ['value', 'validation'],
  emits: ['update:value'],
  methods: {

  }
}
</script>

<template>
  <div>
    <h3 class="subtitle">Actions</h3>
    <actions
      :value="actions"
      :validation="validation.actions"
      @update:value="$emit('update:value', {'actions': $event})"
    />
  </div>
</template>

<script>
import Actions from './../actions/Actions.vue'

export default {
  name: 'step-1',
  components: {
    'actions': Actions
  },
  props: {
    actions: {
      type: Object,
      required: true
    },
    validation: {
      type: Object,
      required: true
    }
  },
  emits: ['update:value']
}
</script>

<template>
  <div class="actions">
    <!-- <action-phone-call
      :value="value.action_phone_call"
      :validation="validation.action_phone_call"
      @update:value="$emit('update:value', {...value, 'action_phone_call': $event})"
    />
    <action-sms
      :value="value.action_sms"
      :validation="validation.action_sms"
      @update:value="$emit('update:value', {...value, 'action_sms': $event})"
    /> -->
    <action-email
      :value="value.action_email"
      :validation="validation.action_email"
      @update:value="$emit('update:value', {...value, 'action_email': $event})"
    />
    <action-publish-event
      :value="value.action_publish_event"
      :validation="validation.action_publish_event"
      @update:value="$emit('update:value', {...value, 'action_publish_event': $event})"
    />
    <action-network-request
      :value="value.action_network_request"
      :validation="validation.action_network_request"
      @update:value="$emit('update:value', {...value, 'action_network_request': $event})"
    />
  </div>
</template>

<script>
// import ActionPhoneCall from './ActionPhoneCall.vue'
// import ActionSMS from './ActionSMS.vue'
import ActionEmail from './ActionEmail.vue'
import ActionPublishEvent from './ActionPublishEvent.vue'
import ActionNetworkRequest from './ActionNetworkRequest.vue'

export default {
  name: 'actions',
  components: {
    // 'action-phone-call': ActionPhoneCall,
    // 'action-sms': ActionSMS,
    'action-email': ActionEmail,
    'action-publish-event': ActionPublishEvent,
    'action-network-request': ActionNetworkRequest
  },
  props: ['value', 'validation'],
  emits: ['update:value'],
  methods: {

  }
}
</script>

<style>
.actions {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
}
.actions .action:not(:last-child) {
  margin-bottom: 15px;
}
.actions .action > .field,
.actions .action .action-fields > .field {
  padding: 15px;
}
.actions .action .action-header {
  background-color: #f1f1f1;
  margin-bottom: 0;
}
.actions .action:first-child .action-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>

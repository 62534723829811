<template>
  <div class="container is-fluid" :style="{'padding-top': '40px'}">

    <h3 class="is-size-3" :style="{marginBottom: '2rem'}">Trigger an event</h3>

    <event-form
      @close="$router.push({name: 'monitors_list'})"
      @submitted="$router.push({name: 'monitors_list'})"
    />

  </div>
</template>

<script>
/* istanbul ignore file */

import EventForm from './../forms/events/EventForm.vue'

export default {
  name: 'EventFormScreen',
  components: {
    'event-form': EventForm
  },
}
</script>

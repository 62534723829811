<template>
  <div class="rule">
    <div
      class="rule-content level"
    >
      <!-- Left side -->
      <div class="level-left">
        <div v-if="showDragHandle" class="level-item">
          <span class="button is-light drag-handle">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'bars']" />
            </span>
          </span>
        </div>
        <div class="level-item">
          <button
            :data-tooltip="stateValue.negated?'Rule negation is active':'Rule negation is inactive'"
            :class="['button', stateValue.negated?'is-white':'is-light']"
            @click.prevent="stateValue = {...stateValue, 'negated': !stateValue.negated}"
          >
            <span
              :class="['icon', 'is-small', stateValue.negated?'has-text-danger':'has-text-gray']"
            >
              <font-awesome-icon :icon="['fas', 'minus-circle']" />
            </span>
          </button>
        </div>
        <div class="level-item">
          <component
            v-if="stateValue.type.startsWith('selector')"
            v-bind:is="`rule-${stateValue.type}`"
            :value="stateValue"
            @update:value="stateValue = Object.assign({}, $event)"
            :label="meta?get_user_friendly_label(stateValue.key):''"
            :hideKeyControls="meta?true:false"
          />
          <component
            v-else
            v-bind:is="`rule-${stateValue.type}`"
            :value="stateValue"
            @update:value="stateValue = Object.assign({}, $event)"
          />
        </div>
      </div>

      <!-- Right side -->
      <div v-if="showRemoveButton" class="level-right">
        <div class="level-item">
          <button class="button is-light" @click.prevent="$emit('remove')" data-tooltip="Remove">
            <span class="icon is-small has-text-danger">
              <font-awesome-icon :icon="['fas', 'times']" />
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- TODO: drag&drop of selector rules across different operator rules (i.e. from an AND/OR rule into another) is not persisted properly -->
    <!-- the behavior seems to exist since before the migration to Vue 3 -->
    <draggable
      v-if="stateValue.type === 'operator'"
      class="sub-rules"
      v-model="stateValue.rules"
      group="rules"
      handle=".drag-handle"
    >
      <rule
        v-for="(rule, index) in stateValue.rules"
        :key="index"
        :meta="meta"
        :value="rule"
        @update:value="stateValue = {...stateValue, 'rules': stateValue.rules.map((r, i) => i===index ? $event : r)}"
        @remove="stateValue = {...stateValue, 'rules': stateValue.rules.filter((r, i) => i!==index)}"
      />
      <template v-slot:footer>
        <component
          v-bind:is="meta?'rule-add-meta':'rule-add'"
          @update:value="stateValue = {...stateValue, 'rules': [...stateValue.rules, $event]}"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import _ from 'lodash'

import draggable from './../../../../vendor/vuedraggable'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import RuleOperator from './RuleOperator.vue'
import RuleSelector from './RuleSelector.vue'
import RuleSelectorDateTime from './RuleSelectorDateTime'
import RuleSelectorMinute from './RuleSelectorMinute'
import RuleSelectorHour from './RuleSelectorHour'
import RuleSelectorDayOfMonth from './RuleSelectorDayOfMonth'
import RuleSelectorMonth from './RuleSelectorMonth'
import RuleSelectorYear from './RuleSelectorYear'
import RuleSelectorDayOfWeek from './RuleSelectorDayOfWeek'
import RuleAdd from './RuleAdd.vue'
import RuleAddMeta from './RuleAddMeta.vue'

import { get_user_friendly_label } from './../../../../utils/rules'

export default {
  name: 'rule',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  components: {
    'draggable': draggable,
    'rule-operator': RuleOperator,
    'rule-selector': RuleSelector,
    'rule-selector-datetime': RuleSelectorDateTime,
    'rule-selector-minute': RuleSelectorMinute,
    'rule-selector-hour': RuleSelectorHour,
    'rule-selector-day-of-month': RuleSelectorDayOfMonth,
    'rule-selector-month': RuleSelectorMonth,
    'rule-selector-year': RuleSelectorYear,
    'rule-selector-day-of-week': RuleSelectorDayOfWeek,
    'rule-add': RuleAdd,
    'rule-add-meta': RuleAddMeta
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    showDragHandle: {
      type: Boolean,
      default: true
    },
    meta: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value', 'remove'],
  data() {
    return {
      stateValue: Object.assign({}, this.value),
    }
  },
  watch: {
    v$: {
      handler: async function (currentv$) {
        await this.$nextTick()
        const $invalid = (currentv$.value.$invalid || Boolean(this.stateValue.$invalid_inner))
        if (this.stateValue.$invalid !== $invalid) {
          this.stateValue = {...this.stateValue, '$invalid': $invalid}
        }
      },
      deep: true
    },
    value: {
      handler: function (newValue, oldValue) {
        if (!_.isEqual(oldValue, newValue)) {
          this.stateValue = Object.assign({}, newValue)
        }
      },
      deep: true
    },
    stateValue: {
      handler: async function (newStateValue, oldStateValue) {
        if (!_.isEqual(oldStateValue, newStateValue)) {
          // emit new value for parent component
          this.$emit('update:value', Object.assign({}, newStateValue))
          // touch rule validation (this fires the v$ watcher, which will attempt an update to the special value.$invalid attr if needed)
          await this.$nextTick(); // wait for v$ to be populated
          this.v$.value.$touch();
        }
      },
      deep: true,
      immediate: true // run handler asap (else, stateValue's initialization from the 'value' prop won't be taken into account)
    }
  },
  validations: {
    value: {
      negated: {
        required,
        mustBeBoolean: (value) => typeof value === 'boolean'
      }
      // NOTE: other fields are validated inside the respective inner RuleX.vue component depending on rule type.
    }
  },
  methods: {
    get_user_friendly_label
  }
}
</script>

<style scoped>
.rule {
  padding-top: 10px;
}
.rule-add {
  padding-top: calc(10px + 3px);
}
.rule .rule-content {
  background-color: #eeeeee;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 3px;
}
.rule .sub-rules {
  padding-left: 50px;
  padding-left: calc(40px + 5px + 0.75rem);
}
.rule .sub-rules > div {
  position: relative;
}
.rule .sub-rules > div::before, .rule .sub-rules > div::after {
    content: "";
    position: absolute;
    left: -31px;
}
.rule .sub-rules > div::before {
    border-top: 1px solid #888;
    top: 34px;
    width: 20px;
    height: 0;
}
.rule .sub-rules > div::after {
    border-left: 1px solid #888;
    height: 100%;
    width: 0px;
    top: 2px;
}
.rule .sub-rules > div:last-child::after {
    height: 33px;
}
</style>

import { reactive } from 'vue'
import api_cache from './../apis/user_api/cache'

const UserCache = reactive({
  data: null,
  tag_map: new Map(),
  actions_phone_numbers: []
})

UserCache.install = function(app){
  app.config.globalProperties.$userCache = UserCache
}

UserCache.initialize = function() {
  return api_cache.get()
    .then((response) => {
      if (response.status == 200) {
        this.data = response.data
        this.tag_map = this?.data?.user?.cache?.tags?.length
          ?new Map(this.data.user.cache.tags.map(obj => [obj.hash, obj.tag]))
          :new Map()
        this.actions_phone_numbers = this?.data?.user?.cache?.actions_phone_numbers?.length
          ?this.data.user.cache.actions_phone_numbers
          :[]
        return Promise.resolve()
      } else {
        return Promise.reject({response})
      }
    })
}

UserCache.update = function(data) {
  return api_cache.update_cache(data)
    .then((response) => {
      if (response.status == 204) {
        return Promise.resolve()
      }
      return Promise.reject({response})
    })
}


export default UserCache;

<template>
  <div class="card ">
    <div class="card-content">
      <div class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <strong>{{data.name}}</strong>
          </div>
          <div class="level-item">
            <div class="tags">
              <span
                v-for="device in data.devices"
                :key="device"
                :class="['tag is-light', device === ALL_DEVICES_KEY?'is-success':'']"
              >
                {{get_device_label(device)}}
              </span>
            </div>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <span class="timestamp has-text-grey is-size-7">Created <timeago :datetime="data.created_at"></timeago></span>
          </div>
          <div class="level-item">
            <monitor-status-label
              :status="data.status"
              @update_status="$emit('update_status', $event)"
            />
          </div>
          <div class="level-item">
            <div class="buttons">
              <div
                :class="['options-button', 'dropdown', 'is-right', {'is-active': isDropdownActive}]"
                v-on-clickaway="away"
              >
                <div
                  class="dropdown-trigger"
                  @click="isDropdownActive=!isDropdownActive"
                >
                  <button class="button is-white">
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu">
                  <div class="dropdown-content">
                    <a
                      href="#"
                      :class="['dropdown-item', {'is-disabled': ['active', 'deploying', 'deployment_queued'].indexOf(data.status) >= 0}]"
                      @click.prevent="$emit('update_status', 'active')"
                    >
                      <span class="icon is-small">
                        <font-awesome-icon :icon="['fas', 'play']" />
                      </span>
                      Deploy
                    </a>
                    <a
                      href="#"
                      :class="['dropdown-item', {'is-disabled': ['deactivated', 'deactivating', 'deactivation_queued'].indexOf(data.status) >= 0}]"
                      @click.prevent="$emit('update_status', 'deactivated')"
                    >
                      <span class="icon is-small">
                        <font-awesome-icon :icon="['fas', 'pause']" />
                      </span>
                      Deactivate
                    </a>
                    <hr class="dropdown-divider" />
                    <a
                      href="#"
                      class="dropdown-item edit-button"
                      @click.prevent="$emit('edit')"
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </div>
              <button
                :class="['delete-button button is-white', {'is-loading': isDeleting}]"
                @click.prevent="$emit('delete')"
              >
                <span :class="['icon is-small', {'has-text-danger': !isDeleting}]">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import {
  ALL_DEVICES_KEY,
  MONITORS_REFRESH_INTERVAL
} from './../../constants'
import MonitorStatusLabel from './../common/MonitorStatusLabel.vue'

export default {
  name: 'monitors-list-item',
  mixins: [ clickaway ],
  components: {
    'monitor-status-label': MonitorStatusLabel
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isDeleting: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh', 'beforeUnmount', 'update_status', 'delete', 'edit'],
  data() {
    return {
      isDropdownActive: false,
    }
  },
  computed: {
    ALL_DEVICES_KEY: function () {
      return ALL_DEVICES_KEY
    }
  },
  methods: {
    away() {
      this.isDropdownActive = false;
    },
    get_device_label(key) {
      switch (key) {
        case ALL_DEVICES_KEY:
          return 'All devices'
        default:
          return this.$userCache.tag_map.get(key)?this.$userCache.tag_map.get(key):key.substring(0,8)
      }
    },
    emit_refresh() {
      this.$emit('refresh')
    }
  },
  mounted() {
    this.refreshTimer = setInterval(
      this.emit_refresh,
      MONITORS_REFRESH_INTERVAL
    )
  },
  beforeUnmount() {
    this.$emit('beforeUnmount')
    clearInterval(this.refreshTimer)
  }
}
</script>

<style scoped>
.is-disabled{
  pointer-events: none;
  opacity: .65;
}
</style>

export const ALL_DEVICES_KEY = 'all'

export const MONITORS_REFRESH_INTERVAL = 10 * 1000

export const MONITOR_FORM_INITIAL_VALUE_FACTORY = function () {
  return {
    name: '',
    devices: [],
    event_type: 'normal', // 'normal' 'synthetic'
    filter: {
      basic: {
        _active: false,
        button: 'pressed'
      },
      standard: {
        _active: false,
        rule: {
          type: 'selector', // (should not be changed, api expects a 'selector' for the standard filter)
          negated: false,
          per_key: false,
          key: '',
          comparator: 'eq',
          value: ''
        }
      },
      advanced: {
        _active: false,
        rule: null
          // {
          //   negated: false,
          //   type: 'operator', // 'operator' 'selector'
          //   operator: 'and', // 'and' 'or'
          //   rules: [
          //     {
          //       negated: false,
          //       type: 'selector',
          //       per_key: true, // if false, 'key' is ignored
          //       key: 'cpu',
          //       comparator: 'gt', // gt gte lt lte eq contains
          //       value: 'hello',
          //     }
          //   ]
          // }

      }
    },
    filter_meta: {
      _active: false,
      standard: {
        _active: false,
        rule: {
          negated: false,
          type: 'selector-datetime',
          per_key: true,
          key: 'publishTime',
          comparator: 'eq',
          value: ''
        }
      },
      advanced: {
        _active: false,
        rule: null
          // {
          //   negated: false,
          //   type: 'operator', // 'operator' 'selector'
          //   operator: 'and', // 'and' 'or'
          //   rules: [
          //     {
          //       negated: false,
          //       type: 'selector',
          //       per_key: true, // if false, 'key' is ignored
          //       key: 'cpu',
          //       comparator: 'gt', // gt gte lt lte eq contains
          //       value: 'hello',
          //     }
          //   ]
          // }

      }
    },
    actions: {
      action_phone_call: {
        _active: false,
        phone_number: '',
        message: ''
      },
      action_sms: {
        _active: false,
        phone_number: '',
        message: ''
      },
      action_email: {
        _active: false,
        email_address: '',
        message: ''
      },
      action_publish_event: {
        _active: false,
        data: {}
      },
      action_network_request: {
        _active: false,
        source: 'cloud', // 'cloud' or 'device'
        device: null, // required if source === 'device'
        type: 'GET', // 'GET' or 'POST'
        url: '',
        body: '' // required if type === 'POST'
      },
    }
  }
}

export const MONITOR_FORM_RULE_FACTORY = function (rule_type) {
  switch (rule_type) {
    case 'and_operator':
      return {
        negated: false,
        type: 'operator',
        operator: 'and',
        rules: []
      }
    case 'or_operator':
      return {
        negated: false,
        type: 'operator',
        operator: 'or',
        rules: []
      }
    case 'selector':
      return {
        negated: false,
        type: 'selector',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: ''
      }
    case 'selector-datetime':
      return {
        negated: false,
        type: 'selector-datetime',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: ''
      }
    case 'selector-minute':
      return {
        negated: false,
        type: 'selector-minute',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: 0
      }
    case 'selector-hour':
      return {
        negated: false,
        type: 'selector-hour',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: 0
      }
    case 'selector-day-of-month':
      return {
        negated: false,
        type: 'selector-day-of-month',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: 1
      }
    case 'selector-month':
      return {
        negated: false,
        type: 'selector-month',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: 1
      }
    case 'selector-year':
      return {
        negated: false,
        type: 'selector-year',
        per_key: false,
        key: null,
        comparator: 'eq',
        value: 1
      }
    case 'selector-day-of-week':
      return {
        negated: false,
        type: 'selector-day-of-week',
        per_key: false,
        key: null,
        value: []
      }
  }
}

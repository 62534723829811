<template>
  <div>

    <step-0
      v-if="step === 0"

      :name="value.name"
      :devices="value.devices"
      :event_type="value.event_type"
      :filter="value.filter"
      :filter_meta="value.filter_meta"

      :validation="{
        'name': v$.value.name,
        'devices': v$.value.devices,
        'event_type': v$.value.event_type,
        'filter': v$.value.filter,
        'filter_meta': v$.value.filter_meta
      }"

      @update:value="value = {...value, ...$event}"

      :handleFilterModeChange="handleFilterModeChange"
      :handleFilterMetaModeChange="handleFilterMetaModeChange"
    />

    <step-1
      v-else-if="step === 1"

      :actions="value.actions"

      :validation="{
        'actions': v$.value.actions
      }"

      @update:value="value = {...value, ...$event}"
    />

    <div class="level" :style="{marginTop: '2rem'}">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button
            class="back-button button is-light"
            :disabled="submitting"
            @click.prevent="handleStepBack"
          >{{step === 0 ? 'Cancel' : 'Back'}}</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
            :disabled="v$.$validationGroups[`step${step}`].$invalid"
            :data-tooltip="v$.$validationGroups[`step${step}`].$invalid?'Please ensure all fields are valid':null"
            @click.prevent="handleStepForward"
          >
            {{step === (constants.STEPS_COUNT-1) ? 'Save' : 'Continue'}}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, email, url } from '@vuelidate/validators'

import {
  MONITOR_FORM_INITIAL_VALUE_FACTORY
} from './../../../constants.js'
import api_monitors from './../../../apis/monitors_api/monitors'
import { get_api_ready_form_data, get_form_ready_data } from './../../../utils/monitor_form'
import { get_sso_url } from './../../../utils/sso.js'
import { is_phone_number_verified } from './../../../utils/jwt.js'

import Step0 from './steps/Step0.vue'
import Step1 from './steps/Step1.vue'

const STEPS_COUNT = 2 // must match actual number of steps at all times

export default {
  name: 'monitors-form',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  components: {
    'step-0': Step0,
    'step-1': Step1
  },
  props: {
    monitor_to_edit: {
      type: Object
    }
  },
  data () {
    return {
      step: 0,
      value: this.monitor_to_edit?get_form_ready_data(this.monitor_to_edit):MONITOR_FORM_INITIAL_VALUE_FACTORY(),
      submitting: false
    }
  },
  validations: {
    $validationGroups: {
      step0: ['value.id', 'value.name', 'value.devices', 'value.event_type', 'value.filter', 'value.filter_meta'],
      step1: ['step0', 'value.actions'],
    },
    value: {
      id: {

      },
      name: {
        required
      },
      devices: {
        required
      },
      event_type: {
        required,
        mustBe: (value) => (['normal', 'synthetic'].indexOf(value) >= 0)
      },
      filter: {
        typeIsMutuallyExclusive: (value) => [value.basic._active, value.standard._active, value.advanced._active].filter(v => v).length === 1,
        basic: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          button: {
            requiredIf: requiredIf(function () {
              return this.value.filter.basic._active
            }),
            mustBe: (value, parentVm) => parentVm._active
              ?['pressed'].indexOf(value) >= 0
              :true
          }
        },
        standard: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          rule: {
            requiredIf: requiredIf(function () {
              return this.value.filter.standard._active
            }),
            ruleIsValid: (rule, parentVm) => {
              return parentVm._active
                ?(typeof rule.$invalid === 'undefined' || (typeof rule.$invalid === 'boolean' && !rule.$invalid))
                :true
            }
          },
        },
        advanced: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          rule: {
            requiredIf: requiredIf(function () {
              return this.value.filter.advanced._active
            }),
            ruleIsValid: (rule, parentVm) => {
              return parentVm._active
                ?rule !== null && typeof rule.$invalid === 'boolean' && !rule.$invalid
                :true
            }
          }
        },
      },
      filter_meta: {
        typeIsMutuallyExclusive: (value, parentVm) => (
          parentVm._active?
            ([value.standard._active, value.advanced._active].filter(v => v).length === 1)
            :true
        ),
        _active: {
          mustBeBoolean: (value) => typeof value === 'boolean'
        },
        standard: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          rule: {
            requiredIf: requiredIf(function () {
              return this.value.filter_meta.standard._active
            }),
            ruleIsValid: (rule, parentVm) => {
              return parentVm._active
                ?(typeof rule.$invalid === 'undefined' || (typeof rule.$invalid === 'boolean' && !rule.$invalid))
                :true
            }
          },
        },
        advanced: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          rule: {
            requiredIf: requiredIf(function () {
              return this.value.filter_meta.advanced._active
            }),
            ruleIsValid: (rule, parentVm) => {
              return parentVm._active
                ?rule !== null && typeof rule.$invalid === 'boolean' && !rule.$invalid
                :true
            }
          }
        },
      },
      actions: {
        action_phone_call: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          phone_number: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_phone_call._active
            }),
            isValidPhoneNumber: (value, parentVm) => parentVm._active?(PhoneNumber(value).isValid()):true
          },
          hasVerifiedPhoneNumber: (value) => value._active?(is_phone_number_verified()):true
        },
        action_sms: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          phone_number: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_sms._active
            }),
            isValidPhoneNumber: (value, parentVm) => parentVm._active?(PhoneNumber(value).isValid()):true
          },
          hasVerifiedPhoneNumber: (value) => value._active?(is_phone_number_verified()):true
        },
        action_email: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          email_address: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_email._active
            }),
            email
          }
        },
        action_publish_event: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          data: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_publish_event._active
            })
          },
          _data_isValid: {
            // actual validation is made by the v-jsoneditor component (see actions/ActionPublishEvent.vue)
            requiredIf: requiredIf(function () {
              return this.value.actions.action_publish_event._active
            }),
            mustBeTrue: (value, parentVm) => parentVm._active?(value === true):true
          }
        },
        action_network_request: {
          _active: {
            mustBeBoolean: (value) => typeof value === 'boolean'
          },
          device: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_network_request._active && this.value.actions.action_network_request.source === 'device'
            })
          },
          url: {
            requiredIf: requiredIf(function () {
              return this.value.actions.action_network_request._active
            }),
            url
          }
        }
      }
    }
  },
  emits: ['update:value', 'close', 'submitted'],
  computed: {
    constants: function () {
      // expose js constants for use in template
      return {
        STEPS_COUNT
      }
    }
  },
  methods: {
    handleStepBack() {
      if (this.step === 0) {
        this.$emit('close');
      } else {
        this.step = this.step - 1
      }
    },
    handleStepForward() {
      if (this.step === (STEPS_COUNT-1)) {
        this.handleSubmit()
      } else {
        this.step = this.step + 1
      }
    },
    /** Activates target filter mode and deactivates the rest */
    handleFilterModeChange(target) {
      let filter = {...this.value.filter}
      Object.keys(this.value.filter).forEach((key) => {
        filter[key]._active = (key === target)?true:false
        this.v$.value.filter[key]._active.$touch()
      });

      this.v$.value.filter.$touch(); this.$emit('update:value', {...this.value, 'filter': filter})
    },
    /** Activates target filter_meta mode and deactivates the rest */
    handleFilterMetaModeChange(target) {
      let filter_meta = {...this.value.filter_meta}
      Object.keys(this.value.filter_meta).filter(key => !key.startsWith('_')).forEach((key) => {
        filter_meta[key]._active = (key === target)?true:false
        this.v$.value.filter_meta[key]._active.$touch()
      });

      this.v$.value.filter_meta.$touch(); this.$emit('update:value', {...this.value, 'filter_meta': filter_meta})
    },
    handleSubmit() {
      this.submitting = true

      return Promise.resolve()
        .then(() => (
          this.value.id
            ?api_monitors.edit(this.value.id, get_api_ready_form_data(this.value))
            :api_monitors.create(get_api_ready_form_data(this.value))
        ))
        .then(response => {
          if (response.status == 200) {
            this.submitting = false
            this.$emit('submitted', response.data);

            // cache new actions phone numbers, if any
            const actions_phone_numbers = []
            if (response.data?.action_phone_call?.phone_number && this.$userCache.actions_phone_numbers.indexOf(response.data.action_phone_call.phone_number) < 0) {
              actions_phone_numbers.push(response.data.action_phone_call.phone_number)
            }
            if (response.data?.action_sms?.phone_number && this.$userCache.actions_phone_numbers.indexOf(response.data.action_sms.phone_number) < 0) {
              actions_phone_numbers.push(response.data.action_sms.phone_number)
            }

            if (actions_phone_numbers.length) {
              this.$userCache.update({
                actions_phone_numbers: [
                  ...actions_phone_numbers,
                  ...this.$userCache.actions_phone_numbers
                ]
              })
              .then(() => this.$userCache.initialize())
            }

            return Promise.resolve()
          } else {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.response && error.response.status == 422) {
            alert('Data failed server-side validation.')
            // re-raise, so developers can be alerted of caught edge-case
            return Promise.reject(error)
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  }
}
</script>

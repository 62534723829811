import api_base from './base.js'

const api_devices = {
  get: (id = null) => (
    id !== null
      ?api_base(true).get(`/user/v1/devices/${id}/`)
      :api_base(true).get('/user/v1/devices/')
  ),
  get_logs: (id, params = {}) => (
    api_base(true).get(`/user/v1/devices/${id}/logs/`, {params})
  ),
  reboot: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/reboot/`)
  ),
  forget_network: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/forget-network/`)
  ),
  log_mode_memory: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?enable`, {enable: true, mode: 'memory'})
  ),
  log_mode_file: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?enable`, {enable: true, mode: 'file', file_size: 1024})
  ),
  log_mode_disabled: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?disable`)
  )
}

export default api_devices

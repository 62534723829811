import query_string from 'query-string'

import api_base from './base.js'


const api_monitors = {
  get_single: (id) => api_base(true).get(`/actions/v1/monitors/${id}/`),
  get_list: ({page = null, device_id = null}) => (
    api_base(true)
      .get(`/actions/v1/monitors/?${query_string.stringify({
        ...page?{page}:{},
        ...device_id?{device_id}:{}
      })}`)
  ),
  create: data => api_base(true).post('/actions/v1/monitors/', data),
  edit: (id, data)=> api_base(true).put(`/actions/v1/monitors/${id}/`, data),
  delete: id => api_base(true).delete(`/actions/v1/monitors/${id}/`),
  update_status: (id, target_status) => api_base(true).put(`/actions/v1/monitors/${id}/status_update`, {target_status})
}

export default api_monitors

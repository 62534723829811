<template>
  <div class="action">
    <div class="action-header field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Publish event</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                Publishes a custom event that can be catched by any of the monitors you have set up (useful for chaining actions).
              </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
              <div class="level-item">
                <toggle-button
                  color="#fc6e51"
                  :modelValue="value._active"
                  @update:modelValue="$emit('update:value', {...value, '_active': $event})"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="value._active" class="action-fields">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Associated device</label>
        </div>
        <div class="field-body">
          <div class="field">
            Events published by this action will have the same device ID from the original triggering event.
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Event data <span class="tag is-light">JSON</span></label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <v-jsoneditor
                :options="{
                  mode: 'code',
                  schema: {
                    // This JSON Schema restricts action data to 1-level deep simple objects (no arrays, no bare integers, no bare null)
                    type: 'object',
                    additionalProperties: {
                      type:['string', 'number', 'boolean', 'null']
                    },
                    minProperties: 1
                  },
                  onValidationError: (errors) => {
                    this.validation._data_isValid.$touch();
                    this.$emit('update:value', {...this.value, '_data_isValid': !errors.length})
                  }
                }"
                :plus="false"
                :modelValue="value.data"
                @update:modelValue="$emit('update:value', {...value, 'data': $event})"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VJsoneditor from './../../../../vendor/v-jsoneditor'


export default {
  name: 'action-publish-event',
  props: ['value', 'validation'],
  emits: ['update:value'],
  components: {
    VJsoneditor
  }
}
</script>

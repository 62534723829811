<template>
  <div class="action">
    <div class="action-header field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Network Request</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                Sends a GET or POST request to a URL of your choosing.
              </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
              <div class="level-item">
                <toggle-button
                  color="#fc6e51"
                  :modelValue="value._active"
                  @update:modelValue="$emit('update:value', {...value, '_active': $event})"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="value._active" class="action-fields">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Request source</label>
        </div>
        <div class="field-body">
          <div class="field request-source-field">
            <div class="panel">
              <label class="panel-block">
                <div class="control">
                  <input type="radio" value="cloud" :checked="value.source === 'cloud'" @change="$emit('update:value', {...value, 'source': $event.target.value})"> Cloud
                </div>
              </label>
              <label class="panel-block has-text-grey has-tooltip-left" :data-tooltip="'To be available, soon.'">
                <div class="control">
                  <input :disabled="true" type="radio" value="device" :checked="value.source === 'device'" @change="$emit('update:value', {...value, 'source': $event.target.value})"> Device
                  <br/>
                  <devices-field
                    v-if="value.source === 'device'"
                    :style="{'margin-top': '10px'}"
                    placeholder="Select device"
                    :multiple="false"
                    :show_all_option="false"
                    :modelValue="value.device"
                    @update:modelValue="validation.device.$touch(); $emit('update:value', {...value, 'device': $event})"
                  />
                  <div v-if="validation.device.$error">
                    <p class="help is-danger" v-if="validation.device.requiredIf.$invalid">Field is required</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Request endpoint</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <p class="control">
              <span class="select">
                <select
                  :value="value.type"
                  @input="$emit('update:value', {...value, 'type': $event.target.value})"
                >
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                </select>
              </span>
            </p>
            <p class="control">
              <input
                :class="['input', {'is-danger': validation.url.$error}]"
                type="text"
                placeholder="URL"
                :value="value.url"
                @input="validation.url.$touch(); $emit('update:value', {...value, 'url': $event.target.value})"
              >
            </p>
          </div>
          <div v-if="validation.url.$error">
            <p class="help is-danger" v-if="validation.url.requiredIf.$invalid">Field is required</p>
            <p class="help is-danger" v-if="validation.url.url.$invalid">Field must be a valid URL</p>
          </div>
        </div>
      </div>

      <div v-if="value.type === 'POST'" class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Request body</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <textarea
                class="textarea"
                :modelValue="value.body"
                @update:modelValue="$emit('update:value', {...value, 'body': $event.target.value})"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DevicesField from './../../../common/DevicesField.vue'

export default {
  name: 'action-network-request',
  components: {
    'devices-field': DevicesField
  },
  props: ['value', 'validation'],
  emits: ['update:value'],
  methods: {

  }
}
</script>

<style scoped>
.request-source-field .panel {
  border: 1px solid #ddd;
}
</style>

<template>
  <div>
    <div
      :class="['select', {'is-danger': v$.value.rules.minLength.$invalid || v$.value.rules.required.$invalid}, {'has-tooltip-danger': v$.value.rules.minLength.$invalid || v$.value.rules.required.$invalid}]"
      :data-tooltip="v$.value.rules.minLength.$invalid || v$.value.rules.required.$invalid?'Operator rules (And/Or rules) must have at least 2 sub-rules':null"
    >
      <select :value="value.operator" @update:value="stateValue = {...stateValue, operator: $event.target.value}">
        <option value="and">And (all of the following conditions are satisfied)</option>
        <option value="or">Or (any of the following conditions is satisfied)</option>
      </select>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
export default {
  name: 'rule-operator',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['update:value'],
  data() {
    return {
      stateValue: Object.assign({}, this.value),
    }
  },
  watch: {
    v$: {
      handler: async function (currentv$) {
        await this.$nextTick()
        if (this.stateValue.$invalid_inner !== currentv$.value.$invalid) {
          this.stateValue = {...this.stateValue, '$invalid_inner': currentv$.value.$invalid}
        }
      },
      deep: true
    },
    value: {
      handler: function (newValue, oldValue) {
        if (!_.isEqual(oldValue, newValue)) {
          this.stateValue = Object.assign({}, newValue)
        }
      },
      deep: true
    },
    stateValue: {
      handler: async function (newStateValue, oldStateValue) {
        if (!_.isEqual(oldStateValue, newStateValue)) {
          // emit new value for parent component
          this.$emit('update:value', Object.assign({}, newStateValue))
          // touch rule validation (this fires the v$ watcher, which will attempt an update to the special value.$invalid_inner attr if needed)
          await this.$nextTick(); // wait for v$ to be populated
          this.v$.value.$touch();
        }
      },
      deep: true,
      immediate: true // run handler asap (else, stateValue's initialization from the 'value' prop won't be taken into account)
    }
  },
  validations: {
    value: {
      type: {
        mustBe: (value) => value === 'operator'
      },

      operator: {
        required,
        mustBe: (value) => (['and', 'or'].indexOf(value) >= 0)
      },
      rules: {
        required,
        minLength: minLength(2),
        rulesAreValid: (rules) => Array.isArray(rules) && rules.length === rules.filter(rule => typeof rule.$invalid === 'boolean' && !rule.$invalid).length
      }
    }
  }
}
</script>

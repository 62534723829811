<template>
  <div>

    <div class="container is-fluid">
      <div class="header level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <h3 class="title">My Actions</h3>
          </div>
          <div class="level-item">
            <button
              :class="['refresh-button button is-small', {'is-loading': refreshing}]"
              @click.prevent="refresh()"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'sync']" />
              </span>
            </button>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <div class="buttons">
              <button
                class="button is-white"
                @click.prevent="is_event_modal_active = true"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'play']" />
                </span>
                <span>Trigger an event</span>
              </button>
              <button
                class="add-new-button button is-pozetron-primary"
                @click.prevent="openMonitorsModal()"
              >
                <span>New action</span>
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="loading?loading:undefined" infinite-scroll-distance="10">
        <div v-for="monitor in monitors" :key="monitor.id" :to="`/monitors/${monitor.id}`" :style="{marginBottom: '1rem'}">
          <monitors-list-item
            v-observe-visibility="(isVisible) => visibilityChanged(monitor.id, isVisible)"
            :data="monitor"
            :is-deleting="isDeleting(monitor.id)"
            @delete="deleteMonitor(monitor.id)"
            @edit="openMonitorsModal(monitor)"
            @update_status="updateMonitorStatus(monitor.id, $event)"
            @refresh="monitors_visible[monitor.id]?refreshMonitor(monitor.id):()=>{}"
            @beforeUnmount="visibilityChanged(monitor.id, false)"
          />
        </div>
      </div>

      <div v-if="!loading && !loading_failed && !monitors.length && this.current_page === 1">You have not created any monitors, yet.</div>

      <loading-indicator v-if="loading" text="Loading monitors.." />

      <section v-if="loading_failed" class="section has-text-centered">
        <span class="icon is-large">
          <font-awesome-icon class="fa-3x" :icon="['fas', 'exclamation-triangle']" />
        </span>
        <br/>
        <br/>
        An error happened while fetching data.
        <br/>
        <br/>
        <button
          class="retry-button button is-pozetron-primary"
          @click.prevent="loadMore()"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </span>
          <span>Retry</span>
        </button>
      </section>

    </div>

    <bulma-modal
      v-if="is_monitors_modal_active"
      :is_active="true"
      @close="closeMonitorsModal"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{monitor_to_edit?`Editing: ${monitor_to_edit.name}`:'New Action'}}</p>
        <button
          class="button is-white"
          @click.prevent="closeMonitorsModal()"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'times']" />
          </span>
        </button>
      </header>

      <section class="modal-card-body" :style="{'overflow': 'initial'}">

        <monitors-form
          :monitor_to_edit="monitor_to_edit"
          @close="closeMonitorsModal"
          @submitted="closeMonitorsModal(); refresh()"
        />

      </section>
    </bulma-modal>

    <bulma-modal
      v-if="is_event_modal_active"
      :is_active="true"
      @close="is_event_modal_active = false"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">Trigger an event</p>
        <button
          class="button is-white"
          @click.prevent="is_event_modal_active = false"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'times']" />
          </span>
        </button>
      </header>

      <section class="modal-card-body" :style="{'overflow': 'initial'}">

        <event-form
          @close="is_event_modal_active = false"
        />

      </section>
    </bulma-modal>

  </div>
</template>

<script>
import infiniteScroll from './../../vendor/vue-infinite-scroll'

import api_monitors from './../../apis/monitors_api/monitors.js'
import LoadingIndicator from './../common/LoadingIndicator.vue'
import BulmaModal from './../common/BulmaModal.vue'
import MonitorsListItem from './MonitorsListItem.vue'
import MonitorsForm from './../forms/monitors/MonitorsForm.vue'
import EventForm from './../forms/events/EventForm.vue'
import { get_sso_url } from './../../utils/sso.js'

export default {
  name: 'monitors-list-screen',
  components: {
    'loading-indicator': LoadingIndicator,
    'bulma-modal': BulmaModal,
    'monitors-list-item': MonitorsListItem,
    'monitors-form': MonitorsForm,
    'event-form': EventForm
  },
  directives: {infiniteScroll},
  data() {
    return {
      is_monitors_modal_active: false,
      is_event_modal_active: false,
      refreshing: false,
      refreshing_failed: false,
      loading: false,
      loading_failed: false,
      deleting: [],
      deleting_failed: [],
      monitors: [],
      monitors_visible: {},
      current_page: null,
      pages_count: null,
      monitor_to_edit: null
    }
  },
  methods: {
    openMonitorsModal(monitor_to_edit = null) {
      this.is_monitors_modal_active = true;
      this.monitor_to_edit = monitor_to_edit;
    },
    closeMonitorsModal() {
      this.is_monitors_modal_active = false;
      this.monitor_to_edit = null;
    },
    loadData({page = null, device_id = null}) {
      return api_monitors.get_list({page, device_id})
        .then(response => {
          if (response.status == 200) {
            this.current_page = response.data.current_page
            this.pages_count = response.data.pages_count
            if (page && page > 1) {
              this.monitors = this.monitors.concat(response.data.results)
            } else {
              this.monitors = response.data.results
            }
            return Promise.resolve(response.data.results)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            // api returns 404 on non existing pages (this may happen when the actual number of pages decreases in-between to infinite scrolls)
            return Promise.resolve()
          } else if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    refresh() {
      this.refreshing = true
      this.refreshing_failed = false

      return this.loadData({})
        .then(() => {
          this.refreshing = false
        })
        .catch((error) => {
          this.refreshing = false
          this.refreshing_failed = true
          // re-raise
          return Promise.reject(error)
        })
    },
    loadMore() {
      this.loading = true
      this.loading_failed = false
      return Promise.resolve()
        .then(() => {
          if (this.current_page === null && this.pages_count === null) {
            return this.loadData({}) // first load
          } else if (this.current_page && this.pages_count && this.current_page < this.pages_count) {
            return this.loadData({page : this.current_page + 1}) // next page
          }
        })
        .then(() => {
          this.loading = false
          this.loading_failed = false
        })
        .catch(error => {
          this.loading = false
          this.loading_failed = true
          // re-raise
          return Promise.reject(error)
        })

    },
    markDeleting(monitor_id) {
      this.deleting.push(monitor_id)
      this.deleting_failed = this.deleting_failed.filter(id => monitor_id !== id)
    },
    markDeletingCompleted(monitor_id) {
      this.deleting = this.deleting.filter(id => monitor_id !== id)
      this.deleting_failed = this.deleting_failed.filter(id => monitor_id !== id)
    },
    markDeletingFailed(monitor_id) {
      this.deleting = this.deleting.filter(id => monitor_id !== id)
      this.deleting_failed.push(monitor_id)
    },
    isDeleting(monitor_id) {
      return this.deleting.indexOf(monitor_id) >= 0
    },
    deleteMonitor(id) {
      this.markDeleting(id)
      return api_monitors.delete(id)
        .then(response => {
          if (response.status == 200) {
            this.markDeletingCompleted(id)
            this.monitors = this.monitors.filter(monitor => monitor.id !== id)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.markDeletingFailed(id)
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    updateMonitorStatus(id, target_status) {
      return api_monitors.update_status(id, target_status)
        .then(response => {
          if (response.status == 200) {
            this.monitors = this.monitors.map(monitor => monitor.id === id?response.data:monitor)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    refreshMonitor(id) {
      return api_monitors.get_single(id)
        .then(response => {
          if (response.status == 200) {
            this.monitors = this.monitors.map(monitor => monitor.id === id ? response.data : monitor)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          if (!(error.response && error.response.status == 401)) {
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    visibilityChanged(monitor_id, isVisible) {
      if (isVisible) {
        this.monitors_visible[monitor_id] = true
      } else {
        delete this.monitors_visible[monitor_id]
      }
    }
  },
  mounted: function() {
    this.$userCache.initialize()

    // NOTE: it is the infiniteScroll directive that will make first data fetch
  },
}
</script>

<style>
.header {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.header .title{
  font-weight: normal;
}
</style>

<template>
  <div class="container is-fluid" :style="{'padding-top': '40px'}">

    <h3 class="is-size-3" :style="{marginBottom: '2rem'}">New action</h3>

    <div class="box">
      <monitors-form
        @close="$router.push({name: 'monitors_list'})"
        @submitted="$router.push({name: 'monitors_list'})"
      />
    </div>

  </div>
</template>

<script>
/* istanbul ignore file */

import MonitorsForm from './../forms/monitors/MonitorsForm.vue'

export default {
  name: 'MonitorsFormScreen',
  components: {
    'monitors-form': MonitorsForm
  },
}
</script>

<template>
  <div class="level">
    <!-- Left side -->
    <div class="level-left">
      <div v-if="label" class="level-item">
        {{label}}
      </div>

      <div v-if="!hideKeyControls" class="level-item">
        <div class="field has-addons">
          <div class="control">
            <div class="button is-static toggle-wrapper">
              <toggle-button
                color="#fc6e51"
                :data-tooltip="value.per_key?'The \'per key\' filter is enabled for this rule':'The \'per key\' filter is disabled for this rule'"
                :modelValue="value.per_key"
                @update:modelValue="stateValue = {...stateValue, 'per_key': $event}"
              />
            </div>
          </div>
          <div
            :class="['control', {'has-tooltip-danger': v$.value.key.$error}]"
            :data-tooltip="
              v$.value.key.$error
                ?(
                  v$.value.key.requiredIf.$invalid
                  ?'Field is required'
                  :null
                )
                :null
            "
          >
            <input
              :class="['input', {'is-danger': v$.value.key.$error}]"
              type="text"
              placeholder="Key"
              :disabled="!value.per_key"
              :value="value.per_key?value.key:'[any key]'"
              @input="stateValue = {...stateValue, 'key': $event.target.value}"
            >
          </div>
        </div>
      </div>
      <div class="level-item">
        datetime
      </div>
      <div class="level-item">
        <div class="select">
          <select :value="value.comparator" @update:value="stateValue = {...stateValue, 'comparator': $event.target.value}">
            <option value="eq">is</option>
            <option value="lt">is before</option>
            <option value="lte">is before (and including)</option>
            <option value="gt">is after</option>
            <option value="gte">is after (and including)</option>
          </select>
        </div>
      </div>
      <div
        :class="['level-item', {'has-tooltip-danger': v$.value.value.$error}]"
        :data-tooltip="
          v$.value.value.$error
            ?(
              v$.value.value.required.$invalid
              ?'Field is required'
              :(
                v$.value.value.mustBeISODatetime.$invalid
                ?'Value must be a valid ISO Datetime'
                :null
              )
            )
            :null
        "
      >
        <flat-pickr
          :class="['input', {'is-danger': v$.value.value.$error}]"
          :modelValue="value.value"
          :config="{
            enableTime: true,
            enableSeconds: true,
            time_24hr: true,
            dateFormat: 'Z' // ISO format
          }"
          @update:modelValue="stateValue = {...stateValue, 'value': $event}"
        ></flat-pickr>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import flatPickr from 'vue-flatpickr-component';


export default {
  name: 'rule-selector-datetime',
  components: {
    flatPickr
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    hideKeyControls: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['update:value'],
  data() {
    return {
      stateValue: Object.assign({}, this.value),
    }
  },
  watch: {
    v$: {
      handler: async function (currentv$) {
        await this.$nextTick()
        if (this.stateValue.$invalid_inner !== currentv$.value.$invalid) {
          this.stateValue = {...this.stateValue, '$invalid_inner': currentv$.value.$invalid}
        }
      },
      deep: true
    },
    value: {
      handler: function (newValue, oldValue) {
        if (!_.isEqual(oldValue, newValue)) {
          this.stateValue = Object.assign({}, newValue)
        }
      },
      deep: true
    },
    stateValue: {
      handler: async function (newStateValue, oldStateValue) {
        if (!_.isEqual(oldStateValue, newStateValue)) {
          // emit new value for parent component
          this.$emit('update:value', Object.assign({}, newStateValue))
          // touch rule validation (this fires the v$ watcher, which will attempt an update to the special value.$invalid_inner attr if needed)
          await this.$nextTick(); // wait for v$ to be populated
          this.v$.value.$touch();
        }
      },
      deep: true,
      immediate: true // run handler asap (else, stateValue's initialization from the 'value' prop won't be taken into account)
    }
  },
  validations: {
    value: {
      type: {
        mustBe: (value) => value === 'selector-datetime'
      },

      per_key: {
        required,
        mustBeBoolean: (value) => typeof value === 'boolean'
      },
      key: {
        requiredIf: requiredIf(function () {
          return this.value.per_key
        })
      },
      comparator: {
        mustBe: (value) => ['gt', 'gte', 'lt', 'lte', 'eq'].indexOf(value) >= 0
      },
      value: {
        required,
        mustBeISODatetime: (value) => (
          // regex source: https://stackoverflow.com/a/43931246
          value
            ?Boolean(value.match(
              /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-][01]\d:[0-5]\d)$/
            ))
            :false
        )
      }
    }
  }
}
</script>

<style scoped>
.button.is-static.toggle-wrapper {
  pointer-events: initial;
  cursor: initial;
}
</style>

<template>
  <div>

    <div v-if="submitted" class="message is-success" :style="{marginBottom: '2rem'}">
      <div class="message-body" :style="{'border': 'none'}">
        Event submitted, successfully. You can <a href="#" @click.prevent="reset()">reset</a> the form, to submit a new event.
      </div>
    </div>

    <div :style="{marginBottom: '2rem'}">


      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">On behalf of device</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <devices-field
                v-model="v$.value.device_id.$model"
                placeholder="Select a device"
                :multiple="false"
                :show_all_option="false"
              />
              <div v-if="v$.value.device_id.$error">
                <p class="help is-danger" v-if="v$.value.device_id.required.$invalid">Field is required</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Event type</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="panel" :style="{'margin-top': '1rem'}">
                <label class="panel-block">
                  <input
                    type="radio"
                    value="normal"
                    :checked="v$.value.event_type.$model === 'normal'"
                    @change="v$.value.event_type.$model = $event.target.value"
                  />
                  <span :style="{'margin-right': '.5rem'}">Normal</span>
                  <span class="has-text-grey is-size-7">Select this to trigger the event as if it originated from the selected device, directly.</span>
                </label>
                <label class="panel-block">
                  <input
                    type="radio"
                    value="synthetic"
                    :checked="v$.value.event_type.$model === 'synthetic'"
                    @change="v$.value.event_type.$model = $event.target.value"
                  />
                  <span :style="{'margin-right': '.5rem'}">Synthetic</span>
                  <span class="has-text-grey is-size-7">Select this to trigger the event as if it was generated by some Pozetron Action associated with the selected device, rather than originating from the device itself.</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Event data <span class="tag is-light">JSON</span></label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <v-jsoneditor
                :options="{
                  mode: 'code',
                  schema: {
                    // This JSON Schema restricts action data to 1-level deep simple objects (no arrays, no bare integers, no bare null)
                    type: 'object',
                    additionalProperties: {
                      type:['string', 'number', 'boolean', 'null']
                    },
                    minProperties: 1
                  },
                  onValidationError: (errors) => {
                    this.v$.data_isValid.$model = !errors.length
                  }
                }"
                :plus="false"
                v-model="v$.value.data.$model"
              />
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button
            class="back-button button is-light"
            :disabled="submitting"
            @click.prevent="handleCancel"
          >Cancel</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
            :disabled="v$['value'].$invalid"
            :data-tooltip="v$['value'].$invalid?'Please ensure all fields are valid':null"
            @click.prevent="handleSubmit"
          >
            Send
          </button>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import VJsoneditor from './../../../vendor/v-jsoneditor'

import api_events from './../../../apis/monitors_api/events'
import { get_sso_url } from './../../../utils/sso'

import DevicesField from './../../common/DevicesField.vue'


export function initialState (){
  return {
    value: {
      device_id: null,
      event_type: 'normal', // 'normal' 'synthetic'
      data: {}
    },
    data_isValid: false,
    submitting: false,
    submitted: false,
  }
}


export default {
  name: 'EventForm',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  components: {
    VJsoneditor,
    DevicesField
  },
  data () {
    return initialState()
  },
  validations: {
    value: {
      device_id: {
        required
      },
      event_type: {
        required,
        mustBe: (value) => (['normal', 'synthetic'].indexOf(value) >= 0)
      },
      data: {
        required
      },
    },
    data_isValid: {
      // actual validation is made by the v-jsoneditor component (see actions/ActionPublishEvent.vue)
      required,
      mustBeTrue: (value) => value === true
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState())
    },
    handleCancel() {
      this.$emit('close');
    },
    handleSubmit() {
      this.submitting = true
      this.submitted = false

      return api_events.create(this.value)
        .then(response => {
          if (response.status == 202) {
            this.submitting = false
            this.submitted = true
            return Promise.resolve(response)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  }
}
</script>

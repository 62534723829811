<template>
  <div
    :class="['rule-add', 'dropdown', {'is-active': isAddDropdownActive}]"
    v-on-clickaway="away"
  >
    <div class="dropdown-trigger" @click="isAddDropdownActive=!isAddDropdownActive">
      <button class="button is-light">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </span>
      </button>
    </div>
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', constants.MONITOR_FORM_RULE_FACTORY('and_operator'))"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-info">Operator</span>
            <span class="tag is-light">And</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', constants.MONITOR_FORM_RULE_FACTORY('or_operator'))"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-info">Operator</span>
            <span class="tag is-light">Or</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-datetime'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Datetime</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-minute'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Minute</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-hour'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Hour</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-day-of-month'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Day of month</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-month'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Month</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-year'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Year</span>
          </div>
        </a>
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="isAddDropdownActive = false;$emit('update:value', {...constants.MONITOR_FORM_RULE_FACTORY('selector-day-of-week'), ...{per_key: true, key: 'publishTime'}})"
        >
          <div class="tags has-addons">
            <span class="tag is-light is-success">Selector</span>
            <span class="tag is-light has-text-weight-semibold">{{get_user_friendly_label('publishTime')}}</span>
            <span class="tag is-light">Day of week</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import {
  MONITOR_FORM_RULE_FACTORY
} from './../../../../constants'
import { get_user_friendly_label } from './../../../../utils/rules'

export default {
  name: 'rule-add-meta',
  mixins: [ clickaway ],
  emits: ['update:value'],
  data() {
    return {
      isAddDropdownActive: false,
    }
  },
  computed: {
    constants: function () {
      // expose js constants for use in template
      return {
        MONITOR_FORM_RULE_FACTORY
      }
    }
  },
  methods: {
    get_user_friendly_label,
    away() {
      this.isAddDropdownActive = false;
    }
  }
}
</script>

<style scoped>
.tags{
  flex-wrap: nowrap;
}
</style>

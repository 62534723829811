<template>
  <div :class="['panel', {'is-shadowless': shadowless}]">

    <template
      v-for="section in sections"
      :key="section.key + '-label'"
    >
      <label :class="['panel-block', {'is-active': active_section === section.key}]">
        <div class="control">
          <input type="radio" :value="section.key" :checked="active_section === section.key" @input="$emit('update:modelValue', $event.target.value)"> <strong>{{section.label}}</strong>
        </div>
      </label>
      <div :key="section.key + '-content'" v-if="active_section === section.key" class="panel-content">
        <slot :name="section.key"></slot>
      </div>
    </template>

  </div>
</template>

<script>

export default {
  name: 'bulma-panel-collapsible',
  props: {
    shadowless: {
      type: Boolean,
      default: false
    },
    sections: {
      type: Array,
      required: true
    },
    active_section: {
      type: String
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.panel.is-shadowless {
  border: 1px solid #ededed;
}
.panel-block.is-active {
  background-color: whitesmoke;
}
.panel-content {
  padding: 20px;
}
.panel-content:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
</style>

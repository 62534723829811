import axios from 'axios'

import { get_jwt } from './../../utils/jwt'
import config from './../../../app.config.json'


const api_base = (authorize = false) => {
  const jwt = authorize?get_jwt():null
  return axios.create({
    baseURL: config.MONITORS_API_URL,
    ...jwt
      ?{
        headers: {
          'authorization': `JWT ${get_jwt()}`
        }
      }
      :{}
  })
}

export default api_base
